<template>
  <div :class="[
    'v-input',
    { 'v-input-grid': label.length },
  ]">
    <label v-if="label.length" class="v-input__label">
      {{ $t(`${label}`) }}
    </label>

    <input
      v-if="!typeIsTel"
      :type="type"
      v-bind="$attrs"
      v-model="model"
      :placeholder="placeholder"
    />

    <VueTelInput
      v-else
      ref="telInput"
      v-bind="$attrs"
      v-model="phoneModel"
      :type="type"
      :placeholder="placeholder"
      :required="true"
      :validCharactersOnly="true"
      :defaultCountry="getCurrentLangCode"
      wrapperClasses="v-input__tel"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VueTelInput } from 'vue-tel-input';

export default {
  name: 'VInputUI',
  components: {
    VueTelInput,
  },

  props: {
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    value: null,
    placeholder: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('general', [
      'getCurrentLangCode',
    ]),

    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change-input-value', value);
      },
    },
    phoneModel: {
      get() {
        return this.value;
      },
      set() {
        const validValue = this.$refs.telInput.phoneObject.number.international;
        this.$emit('change-input-value', validValue);
      },
    },
    typeIsTel() {
      return this.type === 'tel';
    },
  },
}
</script>

<style lang="scss">
.v-input-grid {
  display: grid;
  grid-template-rows: repeat(2, max-content);
  grid-row-gap: 4px;
}

.v-input__label {
  font-size: 12px;
  font-weight: 500;
  color: #1E1E1E;
}

.v-input__tel {
  border: 1px solid #E6E9F4 !important;
  box-shadow: none !important;
}

.dropdown {
  outline: none !important;
}
</style>
